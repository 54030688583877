<messages>["./ReportList"]</messages>

<template>
  <base-layout mw2>
    <v-col cols="12">
      <v-card class="search-result mb-6">
        <v-card-title primary-title>
          <v-row>
            <v-col cols="12" sm="6">
              <div
                v-t="'list.title'"
                class="text-h5"/>
              <div
                v-t="'list.subTitle'"
                class="cgwng-subheading"/>
            </v-col>
            <v-col cols="12" sm="6">
              <client-select
                v-if="mayManageForeignObjects"
                v-model="clientId"
                for-view
                :disabled="isLoading"/>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- list -->
        <v-card-text>
          <loading-error-component :error="error" :error-text="errorText" :is-loading="isLoading">
            <v-data-table
              hide-default-footer
              :headers="headers"
              :items="reports"
              :items-per-page="-1"
              :no-results-text="noResultsText (isLoading)"
              :loading="isLoading"
              class="elevation-1">
              <template #item="props">
                <tr>
                  <td>
                    <a
                      :href="props.item.url"
                      target="blank"
                      v-text="formatDate (props.item.referenceDate)"/>
                  </td>
                  <td v-text="$t (`list.scope.${props.item.scope}`)"/>
                  <td v-if="mayManageForeignObjects">
                    <client-link :id="props.item.clientId"/>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </loading-error-component>
        </v-card-text>
      </v-card>
    </v-col>
  </base-layout>
</template>

<script>
  import BaseLayout from '@/app/core/components/BaseLayout'
  import ClientLink from '@/app/core/components/ClientLink'
  import ClientSelect from '@/app/core/components/ClientSelect'
  import LoadingErrorComponent from '@/app/core/components/LoadingErrorComponent'

  import {mapGetters, mapActions} from 'vuex'
  import errorTranslator from '@/app/services/errortranslator'

  import {noResultsText} from '@/app/core/mixins/PaginationComponent'

  export default {
    name: 'Reports',
    components: {
      BaseLayout,
      ClientLink,
      ClientSelect,
      LoadingErrorComponent
    },

    data () {
      return {
        isLoading: false,
        clientId: this.actingClientId,
        reports: [],
        error: false,
        errorText: undefined
      }
    },

    computed: {
      ...mapGetters ('auth', [
        'actingClientId',
        'mayManageForeignObjects'
      ]),

      headers () {
        return [
          'referenceDate',
          'scope',
          ...(this.mayManageForeignObjects ? ['clientId'] : [])
        ].map (value => ({
          text: this.$t (`label.${value}`),
          value
        }))
      }
    },

    watch: {
      clientId: {
        handler (newValue) {
          if (newValue) {
            this.loadReports ()
          } else {
            this.clientId = this.actingClientId
          }
        },
        immediate: true
      }
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      // default text properties, which can be used for `VDataTable` component
      noResultsText,

      setErrorWhileLoading (error) {
        this.error = error
      },

      loadReports () {
        this.isLoading = true
        this.setErrorWhileLoading (false)

        this.fetchData ({
          op: 'report/list',
          params: {
            id: this.clientId
          },
          cb: data => {
            this.reports = data.list
          },
          cbError: data => {
            if (data?.result?.[0]?.code === 'error/server/processing') {
              this.errorText = this.$t ('label.error')
            } else {
              this.errorText = errorTranslator (data)
            }
            this.setErrorWhileLoading (true)
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
