<template>
  <v-slide-y-transition mode="out-in">
    <div v-if="!error && !isLoading" key="content">
      <slot name="default"/>
    </div>
    <div v-else-if="isLoading" key="loading">
      <v-progress-linear indeterminate/>
    </div>
    <div v-else key="error">
      <v-alert>
        {{ errorText }}
      </v-alert>
    </div>
  </v-slide-y-transition>
</template>

<script>
  export default {
    props: {
      errorText: {
        type: String,
        required: false,
        default: 'error'
      },
      error: {
        type: Boolean,
        required: false,
        default: false
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
</script>
